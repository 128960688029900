.p-home {
  $root: &;
  background-image: url('~assets/images/bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .panel {
    padding: rem(18);
  }

  .logo {
    display: flex;
    justify-content: space-between;
  }

  .nutifood {
    width: rem(70);
  }

  .nnris {
    width: rem(72);
  }

  .nnris_big {
    bottom: 20%;
    left: 0;
    position: absolute;
    width: rem(150);
  }

  .form {
    h2 {
      @include font-base(20, 36);
      color: #00609c;
      font-weight: bold;
      margin-bottom: 0;
      margin-top: rem(26);
      text-align: center;
      text-shadow: 0 4px 4px rgba(255, 255, 255, 0.8);
    }


    .field {
      display: flex;
      flex-direction: column;

      label {
        color: #00609c;
        font-weight: 500;
        margin-bottom: rem(8);
        margin-top: rem(12);
        text-align: left;
        @include font-base(14, 21); 
      }

      input {
        background-color: #f6faff;
        background-color: rgba(#f6faff, 0.5);
        border: 1px solid #00609c;
        border-radius: rem(8);
        font-family: inherit;
        height: rem(40);
        outline: 0;
        padding: 0 rem(16);
        text-align: left;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          appearance: none;
          margin: 0;
        }

        &::-webkit-contacts-auto-fill-button {
          background-color: transparent;
          margin: 0;
          width: 0;
        }
      }

      span {
        @include font-base(12, 16);
        color: red;
        font-family: inherit;
        margin-top: rem(4);
        text-align: center;
      }
    }
    
    .phone {
      display: flex;
      gap: rem(10);

      input {
        flex: 1;
      }

      button {
        background-color: #00609c;
        border: 0;
        border-radius: rem(6);
        color: $white;
        flex-shrink: 0;
        font-family: inherit;
        font-weight: 500;
        max-width: rem(140);
        width: 100%;
        @include font-base(14, 21);

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    .react-datepicker__input-container {
      display: flex;
    }

    .react-datepicker__year-wrapper {
      justify-content: center;
      max-width: rem(300);
    }
    
    .react-datepicker__year-text {
      padding: rem(12) rem(16);
    }

    .react-datepicker__header {
      padding-bottom: rem(12);
      padding-top: rem(12);
    }

    .datepicker {
      width: 100%;
    }

    .countdown {
      color: #00609c;
      margin-bottom: rem(20);
      margin-top: rem(8);

      span {
        font-style: italic;
        font-weight: 300;
        // font-weight: 700;
      }
      @include font-base(12, 17);

      .action {
        &[aria-disabled='false'] {
          opacity: 0.5;
        }
        // :not([aria-disabled="true"]) {
        //   cursor: pointer;
        // }
      }
    }

    .submit {
      display: flex;
      justify-content: center;
      margin-top: rem(26);
      position: relative;
      z-index: 2;

      button {
        background-color: transparent;
        border: 0;
        padding: 0;
        width: rem(236);
        // display: flex;
      }

      span {
        color: #00609c;
        font-family: inherit;
        font-weight: 700;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        @include font-base(20, 32);
      }

      .a-icon {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .qr {
    display: none;
    margin-top: rem(63);

    h2 {
      margin-bottom: 0;
      margin-top: 0;
      text-align: center;
      @include font-base(20, 32);

      &.highlight {
        color: #00609c;
        letter-spacing: -0.01em;
      }
    }

    .thumbnail {
      margin: rem(24) auto rem(32);
      max-width: rem(144);
      position: relative;

      .qr_main {
        height: rem(112);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: rem(112);
      }
    }
  }
}
